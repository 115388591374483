.container {
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 20px;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 1;
  @media (min-width: 1200px) {
    padding: 0;
  }
  @media (max-width: 768px) {
    padding: 0;
    max-width: 100%;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
  }

  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
}
